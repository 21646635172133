<template>
  <win-block>
    <template #win-title-left>Ключи пользователей Росреестра</template>

    <template #actions>
      <v-btn outlined color="primary"
              @click="loadKeys()">
        Обновить
      </v-btn>
      <v-spacer/>
      <v-btn outlined color="primary"
             @click="btnAdd()">
        Добавить
      </v-btn>
      <v-btn color="primary"
             @click="btnSaveKey()">
        Сохранить
      </v-btn>
    </template>


    <v-form>
      <!--      label="3 минуты назад Access Denyate"-->
      <!--      hint="30 минут назад последняя успешная попытка"-->
      <!--      value="*******************************************************************************************"-->
      <v-text-field v-for="(key, index) in keys" :key="index"
                    class="mt-7"
                    v-model=key.value
                    :label=getTxtLabel(key)
                    :hint=getTxtHint(key)
                    persistent-hint
                    clearable
                    outlined
      >
      </v-text-field>
      <!--      <v-alert-->
      <!--          prominent-->
      <!--          :type="alert_type"-->
      <!--      >-->
      <!--        <v-row align="center">-->
      <!--          <v-col class="grow">-->
      <!--            {{ alert_text }}-->
      <!--          </v-col>-->
      <!--          <v-col class="shrink">-->
      <!--            <v-btn-->
      <!--                color="primary"-->
      <!--                elevation="1"-->
      <!--                :loading="wait_test"-->
      <!--                @click="btnTest"-->
      <!--            >-->
      <!--              ПРОВЕРКА-->
      <!--            </v-btn>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-alert>-->
    </v-form>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data: () => ({
    keys: items,
    wait_save: false,
    wait_load: false,
  }),
  created() {
    this.loadKeys()
  },
  methods: {
    getTxtLabel(item) {
      if (item.value)
        if (item.value.length > 100)
          return 'Новый ключ доступа'
        else if (item.try_date)
          return this.$lib.timeDeltaToStr(item.try_date) + ' назад запрос c ответом ' + (item.try_result)
        else
          return 'запросов небыло'
      return 'Введите ключ доступа'
    },
    getTxtHint(item) {
      if (item.value)
        if (item.value.length > 100)
          return 'незабудьте сохранить ключ'
        else if (item.success_date)
          return this.$lib.timeDeltaToStr(item.success_date) + ' назад успешный запрос'
        else
          return ''
      return 'пустой ключ будет удален при сохранении'
    },
    btnAdd() {
      this.keys.push({value: ''})
    },
    loadKeys() {
      this.$axios.get(this.$url.server_project_rosreestr_key, {zLoader: [this, 'wait_load']})
          .then(response => {
            this.keys = response.data
          })
    },
    btnSaveKey() {
      this.$axios.post(this.$url.server_project_rosreestr_key, {keys: this.keys}, {zLoader: [this, 'wait_save']})
          .then(response => {
            this.keys = response.data
          })
    },
  }
}

let items = []
// let items = [
//   {
//     "id_md5": '2f8a43552bff4deefe3a3f38ad76ee0b',
//     "success_date": 1670756073,
//     "try_date": 1670756073,
//     "try_result": 'xxxxxxxxxxx xxxxx xxx',
//     "value": 'hazelcast.sessionId=HZ62163C981EF940E2A0B578750D1BA9A8',
//   },
//   {
//     "id_md5": '1f8a43552bff4deefe3a3f38ad76ee79',
//     "success_date": 1670756173,
//     "try_date": 1670756273,
//     "try_result": 'xxxxxxxxxxx xxxxx xxx',
//     "value": 'hazelcast.sessionId=HZ62163C981EF940E2A0B578750D1BA9A8',
//   },
// ]
</script>


<style lang="scss">
.z-spin {
  animation: spin-animation 1.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
</style>